<template>
<div>
    
</div>
</template>
<script>
export default {

}
</script>
<style lang='less' scoped>
@import url('./index.less');
</style>